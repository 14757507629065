import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../components/common/Button';
import Card from '../components/common/Card';
import CardHeading from '../components/common/CardHeading';
import ContentContainer from '../components/common/ContentContainer';
import ContentfulContent from '../components/common/ContentfulContent';
import LoadingButton from '../components/common/LoadingButton';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { extractContent } from './../util';
import { FaqSection } from './events/faq';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ContactFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(1000)
    .required('Name is required'),
  lastName: Yup.string()
    .max(1000)
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  school: Yup.string()
    .max(1000)
    .required('School name is required'),
  district: Yup.string()
    .max(1000)
    .required('District name is required'),
  position: Yup.string()
    .max(1000)
    .required('Position is required'),
  services: Yup.object().shape({
    events: Yup.boolean(),
    professionalDevelopment: Yup.boolean(),
    curriculum: Yup.boolean(),
    other: Yup.boolean()
  }),
  message: Yup.string()
    .max(5000)
    .required('Message is required'),
  receiveEmails: Yup.boolean().required('A selection is required')
});

interface EventInquiryFormProps {
  submitState: SUBMIT_STATE;
  onSubmit: any;
  contactFormLoading: boolean;
}

const EventInquiryForm = (props: EventInquiryFormProps) => {
  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        position: '',
        school: '',
        district: '',
        services: {
          events: false,
          professionalDevelopment: false,
          curriculum: false,
          other: false
        },
        receiveEmails: true,
        inquiryType: 0,
        // eventType: '',
        message: ''
      }}
      validationSchema={ContactFormSchema}
      onSubmit={props.onSubmit}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>First Name</FormLabel>
              <FormField
                type="text"
                name="firstName"
                placeholder="First Name"
                maxLength="40"
              />
              <FormError
                error={errors['firstName']}
                touched={touched['firstName']}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>Last Name</FormLabel>
              <FormField
                type="text"
                name="lastName"
                placeholder="Last Name"
                maxLength="80"
              />
              <FormError
                error={errors['lastName']}
                touched={touched['lastName']}
              />
            </FormGroup>
          </div>
          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Email</FormLabel>
              <FormField
                type="email"
                name="email"
                placeholder="johndoe@gmail.com"
              />
              <FormError error={errors.email} touched={touched.email} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>Position</FormLabel>
              <Select
                isRequired
                name="position"
                onChange={val => setFieldValue('position', val.label)}
                options={[
                  { value: 'Teacher', label: 'Teacher' },
                  { value: 'Coach', label: 'Coach' },
                  { value: 'Principal', label: 'Principal' },
                  { value: 'District Leader', label: 'District Leader' },
                  { value: 'Other', label: 'Other' }
                ]}
              />
              <FormError error={errors.position} touched={touched.position} />
            </FormGroup>
          </div>

          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>School</FormLabel>
              <FormField type="text" name="school" placeholder="School Name" />
              <FormError error={errors.school} touched={touched.school} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>District</FormLabel>
              <FormField
                type="text"
                name="district"
                placeholder="District Name"
              />
              <FormError error={errors.school} touched={touched.school} />
            </FormGroup>
          </div>

          <div className="flex flex-col">
            <div>
              <div className="flex flex-col">
                <FormGroup>
                  <h2 className="text-2xl font-semibold text-primary sans-serif">
                    Please tell us what you are interested in
                  </h2>
                </FormGroup>
                <div className="w-full mb-4">
                  <FormGroup>
                    <label className="flex" htmlFor="services.events">
                      <Field
                        className="w-6 h-6 cursor-pointer"
                        type="checkbox"
                        name="services.events"
                        value={values.services.events}
                        checked={values.services.events}
                      />{' '}
                      <span className="ml-2 font-bold text-gray-700 sans-serif">
                        Events
                      </span>
                    </label>
                  </FormGroup>
                </div>
                <div className="w-full mb-4">
                  <FormGroup>
                    <label
                      className="flex"
                      htmlFor="services.professionalDevelopment"
                    >
                      <Field
                        className="w-6 h-6 cursor-pointer"
                        type="checkbox"
                        name="services.professionalDevelopment"
                        value={values.services.professionalDevelopment}
                        checked={values.services.professionalDevelopment}
                      />{' '}
                      <span className="ml-2 font-bold text-gray-700 sans-serif">
                        Professional Development
                      </span>
                    </label>
                  </FormGroup>
                </div>

                <div className="w-full mb-4">
                  <FormGroup>
                    <label className="flex" htmlFor="services.curriculum">
                      <Field
                        className="w-6 h-6 cursor-pointer"
                        type="checkbox"
                        name="services.curriculum"
                        value={values.services.curriculum}
                        checked={values.services.curriculum}
                      />{' '}
                      <span className="ml-2 font-bold text-gray-700 sans-serif">
                        Curriculum
                      </span>
                    </label>
                  </FormGroup>
                </div>
                <div className="w-full mb-4">
                  <FormGroup>
                    <label className="flex" htmlFor="services.other">
                      <Field
                        className="w-6 h-6 cursor-pointer"
                        type="checkbox"
                        name="services.other"
                        value={values.services.other}
                        checked={values.services.other}
                      />{' '}
                      <span className="ml-2 font-bold text-gray-700 sans-serif">
                        Other
                      </span>
                    </label>
                  </FormGroup>
                </div>
              </div>
            </div>

            <FormGroup>
              <div className="mb-2">
                <FormLabel isRequired>How Can We Help?</FormLabel>
              </div>
              <FormField
                type="textarea"
                component="textarea"
                rows="6"
                name="message"
                placeholder="Tell us how we can help"
              />
              <FormError error={errors.message} touched={touched.message} />
            </FormGroup>
          </div>
          <div className="flex flex-col mb-3">
            <div className="w-full">
              <FormGroup>
                <label className="flex">
                  <Field
                    className="w-6 h-6 cursor-pointer"
                    type="checkbox"
                    name="receiveEmails"
                    value={values.receiveEmails}
                    checked={values.receiveEmails}
                  />{' '}
                  <span className="ml-2 text-gray-700 sans-serif">
                    Yes, I want to receive emails from Mossflower Reading and
                    Writing Project.
                  </span>
                </label>
              </FormGroup>
            </div>
          </div>
          <div className="ml-2">
            <LoadingButton
              text="Submit"
              type="submit"
              isLoading={props.contactFormLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const PDFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(1000)
    .required('Name is required'),
  lastName: Yup.string()
    .max(1000)
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  school: Yup.string()
    .max(1000)
    .required('School name is required'),
  district: Yup.string()
    .max(1000)
    .required('District name is required'),
  position: Yup.string()
    .max(1000)
    .required('Position is required'),
  address1: Yup.string()
    .max(1000)
    .required('Address is required'),
  address2: Yup.string().max(1000),
  city: Yup.string().required('City is required'),
  stateProvince: Yup.string().required('State/Province is required'),
  zipPostal: Yup.string().required('Zip/Postal Code is required'),
  country: Yup.string().required('Country is required'),
  onsite: Yup.boolean(),
  homegrown: Yup.boolean(),
  quickStart: Yup.boolean(),
  largeDistrict: Yup.boolean(),
  message: Yup.string()
    .max(2000)
    .required('Message is required')
});

interface PDInquiryFormProps {
  submitState: SUBMIT_STATE;
  onSubmit: any;
  contactFormLoading: boolean;
}

const PDInquiryForm = (props: PDInquiryFormProps) => {
  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        position: '',

        school: '',
        district: '',
        address1: '',
        address2: '',
        city: '',
        stateProvince: '',
        zipPostal: '',
        country: '',

        // Multi select options
        onsite: '',
        homegrown: '',
        quickStart: '',
        largeDistrict: '',

        message: '',

        receiveEmails: false,
        inquiryType: 1,
        returnURL: 'https://readingandwritingproject.org/get-started'
      }}
      validationSchema={PDFormSchema}
      onSubmit={props.onSubmit}
    >
      {({ errors, touched, values, setValues, setFieldValue }) => (
        <Form>
          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>First Name</FormLabel>
              <FormField
                type="text"
                name="firstName"
                placeholder="First Name"
                maxLength="40"
              />
              <FormError
                error={errors['firstName']}
                touched={touched['firstName']}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>Last Name</FormLabel>
              <FormField
                type="text"
                name="lastName"
                placeholder="Last Name"
                maxLength="80"
              />
              <FormError
                error={errors['lastName']}
                touched={touched['lastName']}
              />
            </FormGroup>
          </div>
          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Email</FormLabel>
              <FormField
                type="email"
                name="email"
                placeholder="johndoe@gmail.com"
              />
              <FormError error={errors.email} touched={touched.email} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired={false}>Phone</FormLabel>
              <FormField type="text" name="phone" placeholder="555-555-5555" />
              <FormError error={errors.phone} touched={touched.phone} />
            </FormGroup>
          </div>
          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>School</FormLabel>
              <FormField type="text" name="school" placeholder="School Name" />
              <FormError error={errors.school} touched={touched.school} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>District</FormLabel>
              <FormField
                type="text"
                name="district"
                placeholder="District Name"
              />
              <FormError error={errors.school} touched={touched.school} />
            </FormGroup>
          </div>

          <div className="flex flex-col w-full mr-4 md:w-1/2 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Position</FormLabel>
              <FormField type="text" name="position" placeholder="Teacher" />
              <FormError error={errors.position} touched={touched.position} />
            </FormGroup>
          </div>

          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Address</FormLabel>
              <FormField
                type="text"
                name="address1"
                placeholder="123 Any Street"
              />
              <FormError error={errors.address1} touched={touched.address1} />
            </FormGroup>
            <FormGroup>
              <FormLabel>Address (line 2)</FormLabel>
              <FormField type="text" name="address2" placeholder="Address 2" />
              <FormError error={errors.address2} touched={touched.address2} />
            </FormGroup>
          </div>
          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>City</FormLabel>
              <FormField
                type="text"
                name="city"
                placeholder="City"
                maxLength="30"
              />
              <FormError error={errors.city} touched={touched.city} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>State</FormLabel>
              <FormField
                type="text"
                name="stateProvince"
                placeholder="State/Province"
                maxLength="30"
              />
              <FormError
                error={errors.stateProvince}
                touched={touched.stateProvince}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired={false}>Zip/Postal Code</FormLabel>
              <FormField
                type="text"
                name="zipPostal"
                placeholder="Zip/Postal Code"
                maxLength="30"
              />
              <FormError error={errors.zipPostal} touched={touched.zipPostal} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired={false}>Country</FormLabel>
              <FormField
                type="text"
                name="country"
                placeholder="USA"
                maxLength="30"
              />
              <FormError
                error={errors['country']}
                touched={touched['country']}
              />
            </FormGroup>
          </div>

          <div className="flex flex-col">
            <FormGroup>
              <h2 className="text-2xl font-semibold text-primary sans-serif">
                Please tell us which services you are interested in
              </h2>
            </FormGroup>
            <div className="w-full mb-4">
              <FormGroup>
                <label className="flex" htmlFor="onsite">
                  <Field
                    className="w-6 h-6 cursor-pointer"
                    type="checkbox"
                    name="onsite"
                    value={values.onsite}
                    checked={values.onsite}
                  />{' '}
                  <span className="ml-2 font-bold text-gray-700 sans-serif">
                    On-Site Professional Development Services
                  </span>
                </label>
              </FormGroup>
            </div>

            <div className="w-full mb-4">
              <FormGroup>
                <label className="flex" htmlFor="homegrown">
                  <Field
                    className="w-6 h-6 cursor-pointer"
                    type="checkbox"
                    name="homegrown"
                    value={values.homegrown}
                    checked={values.homegrown}
                  />{' '}
                  <span className="ml-2 font-bold text-gray-700 sans-serif">
                    Homegrown Summer Institutes
                  </span>
                </label>
              </FormGroup>
            </div>

            <div className="w-full mb-4">
              <FormGroup>
                <label className="flex" htmlFor="quickStart">
                  <Field
                    className="w-6 h-6 cursor-pointer"
                    type="checkbox"
                    name="quickStart"
                    value={values.quickStart}
                    checked={values.quickStart}
                  />{' '}
                  <span className="ml-2 font-bold text-gray-700 sans-serif">
                    Quick Start Conference Days
                  </span>
                </label>
              </FormGroup>
            </div>
            <div className="w-full mb-4">
              <FormGroup>
                <label className="flex" htmlFor="largeDistrict">
                  <Field
                    className="w-6 h-6 cursor-pointer"
                    type="checkbox"
                    name="largeDistrict"
                    value={values.largeDistrict}
                    checked={values.largeDistrict}
                  />{' '}
                  <span className="ml-2 font-bold text-gray-700 sans-serif">
                    Large District Support
                  </span>
                </label>
              </FormGroup>
            </div>
          </div>

          <div className="mt-4">
            <FormGroup>
              <div className="mb-2">
                <FormLabel isRequired>
                  Tell us more about what you are interested in
                </FormLabel>
              </div>
              <FormField
                type="textarea"
                component="textarea"
                rows="6"
                name="message"
                placeholder="Tell us how we can help"
              />
              <FormError error={errors.message} touched={touched.message} />
            </FormGroup>
          </div>

          <div className="flex flex-col mt-4 mb-3">
            <div className="w-full">
              <FormGroup>
                <label className="flex">
                  <Field
                    className="w-6 h-6 cursor-pointer"
                    type="checkbox"
                    name="receiveEmails"
                    value={values.receiveEmails}
                    checked={values.receiveEmails}
                  />{' '}
                  <span className="ml-2 text-gray-700 sans-serif">
                    Yes, I want to receive emails from the Reading and Writing
                    Project.
                  </span>
                </label>
              </FormGroup>
            </div>
          </div>

          <div className="ml-2">
            <LoadingButton
              text="Submit"
              type="submit"
              isLoading={props.contactFormLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const CurriculumFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(1000)
    .required('Name is required'),
  lastName: Yup.string()
    .max(1000)
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  school: Yup.string()
    .max(1000)
    .required('School name is required'),
  district: Yup.string()
    .max(1000)
    .required('District name is required'),
  position: Yup.string()
    .max(1000)
    .required('Position is required'),
  address1: Yup.string()
    .max(1000)
    .required('Address is required'),
  address2: Yup.string().max(1000),
  city: Yup.string().required('City is required'),
  stateProvince: Yup.string().required('State/Province is required'),
  zipPostal: Yup.string().required('Zip/Postal Code is required'),
  country: Yup.string().required('Country is required'),
  gradeBand: Yup.string()
    .max(40)
    .required('Grade Band is required'),
  reading: Yup.boolean(),
  writing: Yup.boolean(),
  phonics: Yup.boolean(),
  decodableTexts: Yup.boolean(),
  highDosageTutoring: Yup.boolean(),
  other: Yup.boolean(),
  message: Yup.string()
    .max(2000)
    .required('Message is required')
});

interface CurriculumInquiryFormProps {
  submitState: SUBMIT_STATE;
  onSubmit: any;
  contactFormLoading: boolean;
}

const CurriculumInquiryForm = (props: CurriculumInquiryFormProps) => {
  return (
    <Formik
      initialValues={{
        // Form fields
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        position: '',
        school: '',
        district: '',
        address1: '',
        address2: '',
        city: '',
        stateProvince: '',
        zipPostal: '',
        country: '',

        gradeBand: '',
        reading: '',
        writing: '',
        phonics: '',
        decodableTexts: '',
        highDosageTutoring: '',
        other: '',
        message: '', // how can we help
        receiveEmails: false,
        inquiryType: 2,
        returnURL: 'https://readingandwritingproject.org/get-started'
      }}
      validationSchema={CurriculumFormSchema}
      onSubmit={props.onSubmit}
    >
      {({ errors, touched, values, setValues, setFieldValue }) => (
        <Form>
          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>First Name</FormLabel>
              <FormField
                type="text"
                name="firstName"
                placeholder="First Name"
                maxLength="40"
              />
              <FormError
                error={errors['firstName']}
                touched={touched['firstName']}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>Last Name</FormLabel>
              <FormField
                type="text"
                name="lastName"
                placeholder="Last Name"
                maxLength="80"
              />
              <FormError
                error={errors['lastName']}
                touched={touched['lastName']}
              />
            </FormGroup>
          </div>
          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Email</FormLabel>
              <FormField
                type="email"
                name="email"
                placeholder="johndoe@gmail.com"
              />
              <FormError error={errors.email} touched={touched.email} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired={false}>Phone</FormLabel>
              <FormField type="text" name="phone" placeholder="555-555-5555" />
              <FormError error={errors.phone} touched={touched.phone} />
            </FormGroup>
          </div>
          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>School</FormLabel>
              <FormField type="text" name="school" placeholder="School Name" />
              <FormError error={errors.school} touched={touched.school} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>District</FormLabel>
              <FormField
                type="text"
                name="district"
                placeholder="District Name"
              />
              <FormError error={errors.school} touched={touched.school} />
            </FormGroup>
          </div>

          <div className="flex flex-col w-full mr-4 md:w-1/2 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Position</FormLabel>
              <FormField type="text" name="position" placeholder="Teacher" />
              <FormError error={errors.position} touched={touched.position} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>Grades</FormLabel>
              <FormField
                type="text"
                name="gradeBand"
                placeholder="K,2,3,4..."
              />
              <FormError error={errors.gradeBand} touched={touched.gradeBand} />
            </FormGroup>
          </div>

          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Address</FormLabel>
              <FormField
                type="text"
                name="address1"
                placeholder="123 Any Street"
              />
              <FormError error={errors.address1} touched={touched.address1} />
            </FormGroup>
            <FormGroup>
              <FormLabel>Address (line 2)</FormLabel>
              <FormField type="text" name="address2" placeholder="Address 2" />
              <FormError error={errors.address2} touched={touched.address2} />
            </FormGroup>
          </div>
          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>City</FormLabel>
              <FormField
                type="text"
                name="city"
                placeholder="City"
                maxLength="30"
              />
              <FormError error={errors.city} touched={touched.city} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>State</FormLabel>
              <FormField
                type="text"
                name="stateProvince"
                placeholder="stateProvince"
                maxLength="30"
              />
              <FormError
                error={errors.stateProvince}
                touched={touched.stateProvince}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired={false}>Zip/Postal Code</FormLabel>
              <FormField
                type="text"
                name="zipPostal"
                placeholder="Zip/Postal Code"
                maxLength="30"
              />
              <FormError error={errors.zipPostal} touched={touched.zipPostal} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired={false}>Country</FormLabel>
              <FormField
                type="text"
                name="country"
                placeholder="USA"
                maxLength="30"
              />
              <FormError error={errors.country} touched={touched.country} />
            </FormGroup>
          </div>

          <div className="flex flex-col">
            <FormGroup>
              <h2 className="text-2xl font-semibold text-primary sans-serif">
                Please tell us which services you are interested in
              </h2>
            </FormGroup>
            <div className="w-full mb-4">
              <FormGroup>
                <label className="flex" htmlFor="reading">
                  <Field
                    className="w-6 h-6 cursor-pointer"
                    type="checkbox"
                    name="reading"
                    value={values.reading}
                    checked={values.reading}
                  />{' '}
                  <span className="ml-2 font-bold text-gray-700 sans-serif">
                    Units of Study in Reading
                  </span>
                </label>
              </FormGroup>
            </div>
            <div className="w-full mb-4">
              <FormGroup>
                <label className="flex" htmlFor="writing">
                  <Field
                    className="w-6 h-6 cursor-pointer"
                    type="checkbox"
                    name="writing"
                    value={values.writing}
                    checked={values.writing}
                  />{' '}
                  <span className="ml-2 font-bold text-gray-700 sans-serif">
                    Units of Study in Writing
                  </span>
                </label>
              </FormGroup>
            </div>
            <div className="w-full mb-4">
              <FormGroup>
                <label className="flex" htmlFor="phonics">
                  <Field
                    className="w-6 h-6 cursor-pointer"
                    type="checkbox"
                    name="phonics"
                    value={values.phonics}
                    checked={values.phonics}
                  />{' '}
                  <span className="ml-2 font-bold text-gray-700 sans-serif">
                    Units of Study in Phonics
                  </span>
                </label>
              </FormGroup>
            </div>
            <div className="w-full mb-4">
              <FormGroup>
                <label className="flex" htmlFor="decodableTexts">
                  <Field
                    className="w-6 h-6 cursor-pointer"
                    type="checkbox"
                    name="decodableTexts"
                    value={values.decodableTexts}
                    checked={values.decodableTexts}
                  />{' '}
                  <span className="ml-2 font-bold text-gray-700 sans-serif">
                    Decodable Texts
                  </span>
                </label>
              </FormGroup>
            </div>
            <div className="w-full mb-4">
              <FormGroup>
                <label className="flex" htmlFor="highDosageTutoring">
                  <Field
                    className="w-6 h-6 cursor-pointer"
                    type="checkbox"
                    name="highDosageTutoring"
                    value={values.highDosageTutoring}
                    checked={values.highDosageTutoring}
                  />{' '}
                  <span className="ml-2 font-bold text-gray-700 sans-serif">
                    High Dosage Tutoring
                  </span>
                </label>
              </FormGroup>
            </div>
            <div className="w-full mb-4">
              <FormGroup>
                <label className="flex" htmlFor="other">
                  <Field
                    className="w-6 h-6 cursor-pointer"
                    type="checkbox"
                    name="other"
                    value={values.other}
                    checked={values.other}
                  />{' '}
                  <span className="ml-2 font-bold text-gray-700 sans-serif">
                    Other
                  </span>
                </label>
              </FormGroup>
            </div>
          </div>

          <div className="mt-4">
            <FormGroup>
              <div className="mb-2">
                <FormLabel isRequired>How can we help?</FormLabel>
              </div>
              <FormField
                type="textarea"
                component="textarea"
                rows="6"
                name="message"
                placeholder="Tell us how we can help"
              />
              <FormError error={errors.message} touched={touched.message} />
            </FormGroup>
          </div>

          <div className="flex flex-col mt-4 mb-3">
            <div className="w-full">
              <FormGroup>
                <label className="flex">
                  <Field
                    className="w-6 h-6 cursor-pointer"
                    type="checkbox"
                    name="receiveEmails"
                    value={values.receiveEmails}
                    checked={values.receiveEmails}
                  />{' '}
                  <span className="ml-2 text-gray-700 sans-serif">
                    Yes, I want to receive emails from the Reading and Writing
                    Project.
                  </span>
                </label>
              </FormGroup>
            </div>
          </div>

          <div className="ml-2">
            <LoadingButton
              text="Submit"
              type="submit"
              isLoading={props.contactFormLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const OtherFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(1000)
    .required('Name is required'),
  lastName: Yup.string()
    .max(1000)
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  school: Yup.string()
    .max(1000)
    .required('School name is required'),
  district: Yup.string()
    .max(1000)
    .required('District name is required'),
  position: Yup.string()
    .max(1000)
    .required('Position is required'),
  address1: Yup.string()
    .max(1000)
    .required('Address is required'),
  address2: Yup.string().max(1000),
  city: Yup.string().required('City is required'),
  stateProvince: Yup.string().required('State/Province is required'),
  zipPostal: Yup.string().required('Zip/Postal Code is required'),
  country: Yup.string().required('Country is required'),
  message: Yup.string()
    .max(2000)
    .required('Message is required')
});

interface OtherInquiryFormProps {
  submitState: SUBMIT_STATE;
  onSubmit: any;
  contactFormLoading: boolean;
}

const OtherInquiryForm = (props: OtherInquiryFormProps) => {
  return (
    <Formik
      initialValues={{
        // Form fields
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        position: '',

        school: '',
        district: '',
        address1: '',
        address2: '',
        city: '',
        stateProvince: '',
        zipPostal: '',
        country: '',
        message: '', // how can we help
        receiveEmails: false,
        inquiryType: 3,
        returnURL: 'https://readingandwritingproject.org/get-started'
      }}
      validationSchema={OtherFormSchema}
      onSubmit={props.onSubmit}
    >
      {({ errors, touched, values, setValues, setFieldValue }) => (
        <Form>
          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>First Name</FormLabel>
              <FormField
                type="text"
                name="firstName"
                placeholder="First Name"
                maxLength="40"
              />
              <FormError
                error={errors['firstName']}
                touched={touched['firstName']}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>Last Name</FormLabel>
              <FormField
                type="text"
                name="lastName"
                placeholder="Last Name"
                maxLength="80"
              />
              <FormError
                error={errors['lastName']}
                touched={touched['lastName']}
              />
            </FormGroup>
          </div>
          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Email</FormLabel>
              <FormField
                type="email"
                name="email"
                placeholder="johndoe@gmail.com"
              />
              <FormError error={errors.email} touched={touched.email} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired={false}>Phone</FormLabel>
              <FormField type="text" name="phone" placeholder="555-555-5555" />
              <FormError error={errors.phone} touched={touched.phone} />
            </FormGroup>
          </div>
          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>School</FormLabel>
              <FormField type="text" name="school" placeholder="School Name" />
              <FormError error={errors.school} touched={touched.school} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>District</FormLabel>
              <FormField
                type="text"
                name="district"
                placeholder="District Name"
              />
              <FormError error={errors.school} touched={touched.school} />
            </FormGroup>
          </div>

          <div className="flex flex-col w-full mr-4 md:w-1/2 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Position</FormLabel>
              <FormField type="text" name="position" placeholder="Teacher" />
              <FormError error={errors.position} touched={touched.position} />
            </FormGroup>
          </div>

          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>Address</FormLabel>
              <FormField
                type="text"
                name="address1"
                placeholder="123 Any Street"
              />
              <FormError error={errors.address1} touched={touched.address1} />
            </FormGroup>
            <FormGroup>
              <FormLabel>Address (line 2)</FormLabel>
              <FormField type="text" name="address2" placeholder="Address 2" />
              <FormError error={errors.address2} touched={touched.address2} />
            </FormGroup>
          </div>
          <div className="flex flex-col w-full mr-4 md:flex-row">
            <FormGroup>
              <FormLabel isRequired>City</FormLabel>
              <FormField
                type="text"
                name="city"
                placeholder="City"
                maxLength="30"
              />
              <FormError error={errors.city} touched={touched.city} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired>State</FormLabel>
              <FormField
                type="text"
                name="stateProvince"
                placeholder="stateProvince"
                maxLength="30"
              />
              <FormError
                error={errors.stateProvince}
                touched={touched.stateProvince}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired={false}>Zip/Postal Code</FormLabel>
              <FormField
                type="text"
                name="zipPostal"
                placeholder="Zip/Postal Code"
                maxLength="30"
              />
              <FormError error={errors.zipPostal} touched={touched.zipPostal} />
            </FormGroup>
            <FormGroup>
              <FormLabel isRequired={false}>Country</FormLabel>
              <FormField
                type="text"
                name="country"
                placeholder="USA"
                maxLength="30"
              />
              <FormError error={errors.country} touched={touched.country} />
            </FormGroup>
          </div>

          <div className="mt-4">
            <FormGroup>
              <div className="mb-2">
                <FormLabel isRequired>How can we help?</FormLabel>
              </div>
              <FormField
                type="textarea"
                component="textarea"
                rows="6"
                name="message"
                placeholder="Tell us how we can help"
              />
              <FormError error={errors.message} touched={touched.message} />
            </FormGroup>
          </div>

          <div className="flex flex-col mt-4 mb-3">
            <div className="w-full">
              <FormGroup>
                <label className="flex">
                  <Field
                    className="w-6 h-6 cursor-pointer"
                    type="checkbox"
                    name="receiveEmails"
                    value={values.receiveEmails}
                    checked={values.receiveEmails}
                  />{' '}
                  <span className="ml-2 text-gray-700 sans-serif">
                    Yes, I want to receive emails from the Reading and Writing
                    Project.
                  </span>
                </label>
              </FormGroup>
            </div>
          </div>

          <div className="ml-2">
            <LoadingButton
              text="Submit"
              type="submit"
              isLoading={props.contactFormLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

interface FormTypeButtonProps {
  text: string;
  isSelected: boolean;
  onClick: any;
}

const FormTypeButton = (props: FormTypeButtonProps) => {
  const classNames = classnames({
    'mr-6 border px-4 py-2 rounded-full font-bold hover:bg-primary hover:text-white sans-serif focus:outline-none text-sm sm:text-base mb-2': true,
    'bg-primary text-white': props.isSelected,
    'border-gray-400 text-gray-500': !props.isSelected
  });

  return (
    <button className={classNames} onClick={props.onClick}>
      {props.text}
    </button>
  );
};

interface FormLabelProps {
  isRequired?: boolean;
  children: React.ReactNode;
}

const FormLabel = (props: FormLabelProps) => {
  return (
    <label>
      <span className="mb-2 font-semibold text-gray-600 sans-serif">
        {props.children}
      </span>{' '}
      {props.isRequired && <span className="text-red-500">*</span>}
    </label>
  );
};

interface FormFieldProps {
  type: string;
  name: string;
  placeholder: string;
  component?: string;
  rows?: string;
  maxLength?: string;
}

const FormField = (props: FormFieldProps) => {
  return (
    <Field {...props} className="px-3 py-2 border border-gray-300 rounded" />
  );
};

interface FormGroupProps {
  children: React.ReactNode;
}
const FormGroup = (props: FormGroupProps) => {
  return <div className="flex flex-col w-full m-2 mt-2">{props.children}</div>;
};

interface FormErrorProps {
  error: string | undefined;
  touched: boolean | undefined;
}

const FormError: React.FC<FormErrorProps> = props => {
  return (
    <p className="mt-1 text-sm text-red-600">
      {props.error && props.touched ? props.error : ' '}
    </p>
  );
};

enum INQUIRY_TYPES {
  EVENTS,
  PD,
  CURRICULUM,
  OTHER
}

enum SUBMIT_STATE {
  IDLE,
  SUBMITTING,
  SUBMIT_ERROR,
  SUBMIT_SUCCESS
}

enum EVENT_FAQ_STATE {
  VISIBLE,
  HIDDEN
}

enum SERVICES_STATE {
  VISIBLE,
  HIDDEN
}

const Contact = props => {
  const [selectedInquiryType, setSelectedInquiryType] = useState(
    INQUIRY_TYPES.EVENTS
  );
  const [submitState, setSubmitState] = useState(SUBMIT_STATE.IDLE);
  const [eventFaqsVisible, setEventFaqsVisible] = useState(
    EVENT_FAQ_STATE.HIDDEN
  );
  const [servicesVisible, setServicesVisible] = useState(SERVICES_STATE.HIDDEN);
  const [contactFormLoading, setContactFormLoading] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const submitForm = useCallback(async values => {
    setContactFormLoading(true);

    const requestInfo = {
      ...values
    };

    try {
      const req = await fetch(
        `${process.env.GATSBY_FUNCTIONS_PATH}/eventContact`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestInfo)
        }
      );

      if (!req.ok) {
        setContactFormLoading(false);
        toast.error('Something went wrong');
        return;
      }

      const result = await req.json();

      if (result.error) {
        setContactFormLoading(false);
        throw new Error('Something went wrong!');
      }
      setContactFormLoading(false);
      setFormSubmitted(true);
    } catch (err) {
      console.log('the err', err);
      toast.error('Something went wrong');
    }
  }, []);

  const data = useStaticQuery(graphql`
    {
      allContentfulEventFaq(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            question
            answer {
              answer
            }
            group
          }
        }
      }
      allContentfulService(
        filter: { node_locale: { eq: "en-US" } }
        sort: { order: ASC, fields: pagePosition }
      ) {
        edges {
          node {
            id
            name
            description {
              description
            }
          }
        }
      }
    }
  `);

  const services = extractContent(data.allContentfulService);

  return (
    <Layout>
      <SEO title="Contact" />
      <ToastContainer />
      <ContentContainer>
        <div className="w-full m-auto lg:w-3/4">
          <Card>
            {formSubmitted ? (
              <section>
                <CardHeading>Thank You!</CardHeading>
                <p className="mt-4 text-lg">
                  We always attempt to respond within 7 business days. If you do
                  not hear back from us within this time frame, please send us
                  an email.
                </p>

                <p className="mt-4 text-lg">
                  We look forward to working with you!
                </p>
                <div className="mt-4">
                  <Link to="/">
                    <Button text="&larr; Home" />
                  </Link>
                </div>
              </section>
            ) : (
              <section>
                <CardHeading>Contact Us</CardHeading>
                <section className="w-full mt-2 mb-4 text-gray-700">
                  <p>
                    We are excited to talk to you! Please fill out the form
                    below and a member of our team will be in touch soon.
                  </p>
                </section>
                <section className="mt-6">
                  {/* <h2 className="mb-2 ml-2 text-2xl font-semibold text-primary">
                    {selectedInquiryType === INQUIRY_TYPES.EVENTS ? (
                      <span className="sans-serif">Events Inquiry</span>
                    ) : selectedInquiryType === INQUIRY_TYPES.PD ? (
                      <span className="sans-serif">
                        Professional Development Inquiry
                      </span>
                    ) : selectedInquiryType === INQUIRY_TYPES.CURRICULUM ? (
                      <span className="sans-serif">Curriculum Inquiry</span>
                    ) : (
                      <span className="sans-serif">Other Inquiries</span>
                    )}
                  </h2>
                  {selectedInquiryType === INQUIRY_TYPES.EVENTS ? (
                    <div className="my-4">
                      <Link to="/events">
                        <Button text="See Our Upcoming Events" />
                      </Link>
                    </div>
                  ) : selectedInquiryType === INQUIRY_TYPES.PD ? (
                    <div className="my-4">
                      <Button
                        text="Learn About Our Services"
                        onClick={() =>
                          setServicesVisible(SERVICES_STATE.VISIBLE)
                        }
                      />
                    </div>
                  ) : null}
                  {eventFaqsVisible === EVENT_FAQ_STATE.VISIBLE ? (
                    <aside className="fixed top-0 bottom-0 right-0 z-10 w-full pt-4 overflow-scroll bg-white shadow-2xl sm:w-3/4 md:w-1/2 lg:w-1/3">
                      <div className="flex justify-end">
                        <button
                          className="m-2 my-auto text-3xl"
                          onClick={() =>
                            setEventFaqsVisible(EVENT_FAQ_STATE.HIDDEN)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="cursor-pointer text-primary hover:text-primary-dark"
                          />
                        </button>
                      </div>
                      <FaqSection
                        faqs={extractContent(data.allContentfulEventFaq)}
                        hasShadow={false}
                      />
                    </aside>
                  ) : null}
                  {servicesVisible === SERVICES_STATE.VISIBLE ? (
                    <aside className="fixed top-0 bottom-0 right-0 z-10 w-full pt-4 overflow-scroll bg-white shadow-2xl sm:w-3/4 md:w-1/2 lg:w-1/3">
                      <div className="flex justify-end">
                        <button
                          className="m-2 my-auto text-3xl"
                          onClick={() =>
                            setServicesVisible(SERVICES_STATE.HIDDEN)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="cursor-pointer text-primary hover:text-primary-dark"
                          />
                        </button>
                      </div>
                      <div className="px-8">
                        <h2 className="mb-4 text-3xl font-bold text-primary">
                          Our Services
                        </h2>
                      </div>
                      {services.map(service => (
                        <>
                          <div className="p-8 mb-2 bg-white rounded-2xl content-body">
                            <p className="font-bold">{service.name}</p>
                            <div className="text-gray-600">
                              <ContentfulContent
                                content={service.description.description}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                    </aside>
                  ) : null} */}

                  {selectedInquiryType === INQUIRY_TYPES.EVENTS && (
                    <EventInquiryForm
                      submitState={submitState}
                      onSubmit={submitForm}
                      contactFormLoading={contactFormLoading}
                    />
                  )}
                  {selectedInquiryType === INQUIRY_TYPES.PD && (
                    <PDInquiryForm
                      submitState={submitState}
                      onSubmit={submitForm}
                      contactFormLoading={contactFormLoading}
                    />
                  )}
                  {selectedInquiryType === INQUIRY_TYPES.CURRICULUM && (
                    <CurriculumInquiryForm
                      submitState={submitState}
                      onSubmit={submitForm}
                      contactFormLoading={contactFormLoading}
                    />
                  )}
                  {selectedInquiryType === INQUIRY_TYPES.OTHER && (
                    <OtherInquiryForm
                      submitState={submitState}
                      onSubmit={submitForm}
                      contactFormLoading={contactFormLoading}
                    />
                  )}
                </section>
              </section>
            )}
          </Card>
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default Contact;
